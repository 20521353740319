"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.environment = {
    production: false,
    baseUrl: 'https://api-dev.fassi.mindtek.it',
    defaultLanguage: 'en',
    hereGeocodingApi: 'https://geocode.search.hereapi.com/v1/geocode',
    hereApiKey: 'AzFGjkhelXhTd0o7hbX2ARHIRloLLqX9R-RUvl8Oqow',
    startLatitude: 41.89193,
    startLongitude: 12.51133
};
