"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var environment_1 = require("environments/environment");
var GeoSearchComponent = /** @class */ (function () {
    function GeoSearchComponent(http) {
        this.http = http;
        this.selection = new core_1.EventEmitter();
        this.searchBox = new forms_1.FormControl();
    }
    GeoSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.options = this.searchBox.valueChanges.pipe(operators_1.debounceTime(200), operators_1.flatMap(function (value) { return !!value ? _this.http.get(environment_1.environment.hereGeocodingApi + "?q=" + value + "&apiKey=" + environment_1.environment.hereApiKey) : rxjs_1.of([]); }), operators_1.map(function (res) { return res['items']; }));
    };
    GeoSearchComponent.prototype.clearSearch = function () {
        this.searchBox.setValue('');
    };
    GeoSearchComponent.prototype.updateSelection = function (event) {
        this.selection.emit(event);
    };
    return GeoSearchComponent;
}());
exports.GeoSearchComponent = GeoSearchComponent;
