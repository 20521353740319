import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, flatMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
  selector: 'ms-geo-search',
  templateUrl: './geo-search.component.html',
  styleUrls: ['./geo-search.component.scss']
})
export class GeoSearchComponent implements OnInit {
  @Output() selection: EventEmitter<any> = new EventEmitter<any>();
  searchBox: FormControl = new FormControl();
  options: Observable<any[]>;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.options = this.searchBox.valueChanges.pipe(
      debounceTime(200),
      flatMap((value: string) => !!value ? this.http.get(`${environment.hereGeocodingApi}?q=${value}&apiKey=${environment.hereApiKey}`) : of([])),
      map(res => res['items'])
    );
  }

  clearSearch() {
    this.searchBox.setValue('');
  }

  updateSelection(event: any) {
    this.selection.emit(event);
  }
}
