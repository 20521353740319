"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var authentication_service_1 = require("app/authentication/authentication.service");
var base_interceptor_1 = require("app/classes/base-interceptor");
var environment_1 = require("environments/environment");
exports.InterceptorSkipHeader = 'X-Skip-Interceptor';
var TokenInterceptor = /** @class */ (function (_super) {
    __extends(TokenInterceptor, _super);
    function TokenInterceptor(auth) {
        var _this = _super.call(this, auth) || this;
        _this.auth = auth;
        return _this;
    }
    TokenInterceptor.prototype.intercept = function (request, next) {
        if (request.url.includes(environment_1.environment.baseUrl) && !request.url.includes('refresh')) {
            return next.handle(this.addAuthenticationToken(request));
        }
        return next.handle(request);
    };
    return TokenInterceptor;
}(base_interceptor_1.BaseInterceptor));
exports.TokenInterceptor = TokenInterceptor;
