import { BaseInterceptor } from 'app/classes/base-interceptor';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class ErrorInterceptor extends BaseInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {
    super(authenticationService);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => {
      const user = this.authenticationService.getUser();
      if (!user) {
        this.authenticationService.logout();
        location.reload();
      }
      if (!request.url.startsWith(environment.baseUrl)) { // Avoid third party requests, i.e. HERE API
        return Observable.throwError(error);
      }
      // We don't want to refresh token for some requests like login or refresh token itself
      // So we verify url and we throw an error if it's the case
      if (request.url.includes('refresh') || request.url.includes('login')) {
        // We do another check to see if refresh token failed
        // In this case we want to logout user and to redirect it to login page
        if (request.url.includes('refresh')) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          location.reload();
        }
        return Observable.throwError(error);
      }
      // If error status is different than 401 we want to skip refresh token
      // So we check that and throw the error if it's the case
      if (error.status !== 401) {
        return Observable.throwError(error);
      }
      return this.authenticationService.apiRefresh().pipe(switchMap(() => {
        // When the call to refreshToken completes we reset the refreshTokenInProgress to false
        // for the next time the token needs to be refreshed
        return next.handle(this.addAuthenticationToken(request));
      })).catch(() => {
        this.authenticationService.logout();
        location.reload();
        return Observable.throwError(error);
      });
    }))
  }
}
